var App = (function() {
    var initHeader = function() {
    	var path = location.pathname;
    	$('.header .link a').each(function(){
    		var thisObj = $(this);
    		if ($(thisObj).attr('href') == path){
    			$(thisObj).parents('li').addClass('active');
    		}
    	});
    	
    	$('#tabpear-phone-icon').on('click', function() {
    		var $this = $(this);
    		var $phoneInfo = $('.header .tabpear-phone-info');
    		$phoneInfo.toggleClass('show');
    		if($phoneInfo.hasClass('show')) {
    			$this.attr('src', '/resources/images/nav_phone_show.png');
    		} else {
    			$this.attr('src', '/resources/images/nav_phone.png');
    		}
    	});
    	
    	
    	$('.header .toggle-btn').off('click').on('click', function() {
    		var $links = $('.header .link-wrapper');
    		if(!$links.hasClass('toggle-show')) {
    			$links.addClass('toggle-show');
    			$links.parents('.container').addClass('full-width');
    			$(this).addClass('show-top');
    			$('.js-link-service > a').attr('href', 'javascript:;');
				$('.js-link-products > a').attr('href', 'javascript:;');
    			$('.js-link-portfolio > a').attr('href', 'javascript:;');
    			$('.js-link-service >a, .js-link-products >a,.js-link-portfolio >a ').off('click').on('click', function() {
    				var $this = $(this);
    				var $sublink = $this.next();
    				if($sublink.hasClass('show')) {
    					$sublink.removeClass('show');
    					$this.parent().removeClass('show');
    				} else {
    					$sublink.addClass('show');
    					$this.parent().addClass('show');
    				}
    			});
    		} else {
    			$links.removeClass('toggle-show');
    			$links.parents('.container').removeClass('full-width');
    			$(this).removeClass('show-top');
    			$('.js-link-service >a').attr('href', '/services');
				$('.js-link-products >a').attr('href', '/products');
    			$('.js-link-portfolio >a').attr('href', '/portfolio');
    			$('.js-link-service >a, .js-link-products >a, .js-link-portfolio >a').off('click');
    		}
    	});
    	
		$(window).scroll(function() {
			if ($(window).scrollTop() > 100) {
				$('.header').addClass('show-scroll');
			} else {
				$('.header').removeClass('show-scroll');
			}
		});
		
		$('.fa-facebook-square').off('click').on('click', function(){
			window.open('//www.facebook.com/tabpearsoftware', '_blank');
		});
		$('.fa-twitter-square').off('click').on('click', function(){
			window.open('//www.twitter.com/TabpearInfo', '_blank');
		});
		$('.fa-youtube-square').off('click').on('click', function(){
			window.open('//www.youtube.com/channel/UCKtU42E4V5N7nVwy2_-9dyA', '_blank');
		});
		
		initMobileServiceAndScrollTop();
		
    };
    
    var initMobileServiceAndScrollTop = function() {
		if($('.mobile-service-wrapper').css('display') != 'none') {
			var $onlineService = $('.online-service');
			if($onlineService) {
				$onlineService.css({'bottom': '105px'});
			}
			$('.mobile-service-wrapper .scroll-top').off('click').on('click', function() {
				$(window).scrollTop(0);
			});
			
			$(window).scroll(function(){
				var scrollTop = $(window).scrollTop();
				if(scrollTop > 200) {
					$onlineService.css({'bottom': '185px'});
					$('.mobile-service-wrapper .scroll-top').fadeIn();
				} else {
					$onlineService.css({'bottom': '105px'});
					$('.mobile-service-wrapper .scroll-top').hide();
				}
			});
		}
    };
    
    var submitContactUsForm = function(){
		$('#contactUsForm #submitBtn').off('click').on('click', function(e) {
			var thisObj = $(this);
			$(thisObj).closest('form').validator('validate');
			var thisObj = $(this);
			if (!$('#contactUsForm div').hasClass('has-error')) {
				thisObj.append('<i class="fa fa-spinner fa-spin fa-fw"></i>');
				thisObj.attr('disabled', true);
				$.post("/contact_us/submit.do", $('#contactUsForm').serialize(), function(data) {
					thisObj.attr('disabled', false);
					$('.fa-spinner', thisObj).remove();
					Common.formValidator(data);
					if (data.success) {
						$('#contactUsForm').prepend('<div class="alert alert-success text-center"  role="alert">Thanks for Your Enquiry.</div>');
						$('#contactUsForm #resetBtn').click();
						setTimeout(function() {
		        			$('.alert-success').remove();
		        		}, 2000);
					}
				}, 'json');
			}
		});
    };


	var submitBlogForm = function(){
		$('#blogForm #submitBtn').off('click').on('click', function(e) {
			var thisObj = $(this);
			$(thisObj).closest('form').validator('validate');
			var thisObj = $(this);
			if (!$('#blogForm div').hasClass('has-error')) {
				thisObj.append('<i class="fa fa-spinner fa-spin fa-fw"></i>');
				thisObj.attr('disabled', true);
				$.post("/comment/save.do", $('#blogForm').serialize(), function(data) {
					thisObj.attr('disabled', false);
					$('.fa-spinner', thisObj).remove();
					Common.formValidator(data);
					if (data.success) {
						$('#blogForm').prepend('<div class="alert alert-success text-center"  role="alert">谢谢您的评论，请等待博主审核！.</div>');
						$('#blogForm #resetBtn').click();
						setTimeout(function() {
							$('.alert-success').remove();
						}, 2000);
					}
				}, 'json');
			}
		});
	};



	var submitBlogFormReply = function(){
		$('#blogFormReply #submitBtnReply').off('click').on('click', function(e) {
            var thisObj = $(this);
			$(thisObj).closest('form').validator('validate');
            var thisObj = $(this);
			if (!$('#blogFormReply div').hasClass('has-error')) {
				thisObj.append('<i class="fa fa-spinner fa-spin fa-fw"></i>');
				thisObj.attr('disabled', true);
				$.post("/comment/save.do", $('#blogFormReply').serialize(), function(data) {
					thisObj.attr('disabled', false);
					$('.fa-spinner', thisObj).remove();
					Common.formValidator(data);
					if (data.success) {
						$('#blogFormReply').prepend('<div class="alert alert-success text-center"  role="alert">谢谢您的评论，请等待博主审核！.</div>');
						$('#blogFormReply #resetBtnReply').click();
						setTimeout(function() {
							$('.alert-success').remove();
						}, 2000);
					}
				}, 'json');
			}
		});
	};





	var initMultiCarousel = function(){
		$('.multi-item-carousel').carousel({
			interval : false
		});
		$('.multi-item-carousel .item').each(function() {
			var next = $(this).next();
			if (!next.length) {
				next = $(this).siblings(':first');
			}
			next.children(':first-child').clone().appendTo($(this));
			if (next.next().length > 0) {
				next.next().children(':first-child').clone().appendTo($(this));
			} else {
				$(this).siblings(':first').children(':first-child').clone().appendTo($(this));
			}
		});
		var carouselBanner = new Hammer($("#carousel-example-generic")[0]);
		// mobile swipe event
		carouselBanner.on("swipeleft", function () {
			console.log('swipeleft');
			$('#carousel-example-generic').carousel('next');
  		});
		carouselBanner.on("swiperight", function () {
			console.log('swiperight');
			$('#carousel-example-generic').carousel('prev');
  		});
    };

    var pageLoad = function ($btn) {
        $btn.off().on('click', function () {
            var num = $btn.data('num');
            var servicesId = $btn.data('servicesId');
            var tagId = $btn.data('tagid');
            $.get('/articles/load',{pageNumber: num, servicesId: servicesId, tagId: tagId}, function (data) {
                var $div = $btn.parent();
                $btn.remove();
                $div.append(data);
            });
        });
    };

	var searchPage = function () {
		$('#searchBtn').click(function () {
			redirectSearch();
		});
		$('#search').keypress(function (e) {
			if (e.which == 13) {
				return redirectSearch();
			}
		});
	};
	var redirectSearch = function () {
		var keyWords = $('#search').val().trim();
		if (keyWords) {
			var url = "../search?keyWord=" + keyWords + "&pageNumber=0";
			url = encodeURI(encodeURI(url));
			window.location.href = url;
		}
		return false;
	}

    return {
    	initMultiCarousel:initMultiCarousel,
    	submitContactUsForm :submitContactUsForm,
    	initHeader: initHeader,
		pageLoad: pageLoad,
		search:searchPage,
		submitBlogForm:submitBlogForm,
		submitBlogFormReply:submitBlogFormReply
    };
})();
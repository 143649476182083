var Common = (function() {
	var initSelectCountry = function() {
		$.each(BFHCountriesList, function(key, value) {
			var option = '<option data-tokens="' + key + ' ' + value + '">' + value + '</option>';
			$('.js-selectpicker').append(option);
		});

	};

	var formValidator = function(data) {
		$(data.data).each(function(index, value) {
			var obj = $('[name="' + value.field + '"]');
			var tip = value.tip;
			var targetParents = $(obj).parents('.form-group');
			var searchFormLength = $(targetParents).removeClass('.has-success').addClass('has-error').addClass(' has-danger');
			$('.help-block', targetParents).html('');
			$('.help-block', targetParents).append('<ul class="list-unstyled"><li>'+tip+'</li></ul>');
		});
	};

	return {
		initSelectCountry : initSelectCountry,
		formValidator: formValidator
	};
})();